<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <perseu-card title="Formulário de Tipo de Fluxo">
      <v-form ref="form" slot="content">
        <v-text-field
          label="Nome"
          v-model="flowType.name"
          :rules="[$rules.required]"
        />
     <!-- <instructions-form v-model="flowType.instructions" />  -->   
        <select-point-order
          v-model="flowType.pointsOrder"
          multiple
          label="Pares"
        />
      </v-form>
      <div slot="actions" class="justify-center">
        <v-btn color="secondary" @click="close">Salvar</v-btn>
      </div>
    </perseu-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FormFlowType",
  components: {
    "instructions-form": () => import("./InstructionsForm"),
    "select-point-order": () => import("@/components/PointsOrder/Select"),
  },
  data: () => ({
    flowType: {
      instructions: [],
      pointsOrder: [],
    },
    dialog: false,
  }),
  methods: {
    create() {
      this.flowType = {
        instructions: [],
        pointsOrder: [],
      };
      this.dialog = true;
    },
    edit(flowTypeParam) {
      this.flowType = flowTypeParam;
      this.dialog = true;
    },
    close() {
    //  if (!this.isValid()) return;
      this.$emit("close", this.flowType);
      this.dialog = false;
    },
    isValid() {
      
      if (!this.$refs.form.validate()) return false;
      if (this.flowType.instructions.length === 0) {
        this.$toasted.global.error({
          message: "É necessário ter no mínimo 1 instrução",
        });
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped></style>
